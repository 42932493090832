<script>
import { SuawMainContent, SuawSubheaderTwo, SuawDivider, SuawHeading, SuawButton, SuawInputGroup, SuawEmptyState } from "@/components";
import WriteWithUs from "@/screens/resourcesIndex/components/WriteWithUs.vue";
import subheaderImage from "@/assets/features/challenges/challenge-discovery-subheader-image.svg";
import ChallengeListPipe from "./components/ChallengeListPipe.vue";
import ChallengeBannerPipe from "../challenge/components/ChallengeBannerPipe.vue";
import { GetCurrentChallengeId } from "./challengeDiscoveryOperations.gql";
export default {
  name: "ChallengeDiscovery",
  components: {
    SuawMainContent,
    SuawSubheaderTwo,
    SuawDivider,
    WriteWithUs,
    ChallengeListPipe,
    ChallengeBannerPipe,
    SuawButton,
    SuawHeading,
    SuawInputGroup,
    SuawEmptyState
  },
  data() {
    return {
      subheaderImage,
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    challenge() {
      return this.resultCurrentChallenge && this.resultCurrentChallenge.length > 0 ? this.resultCurrentChallenge[0] : null;
    },
    challengeId() {
      return this.challenge?.id;
    },
    challengeSlug() {
      return this.challenge?.slug;
    },
    challengeAvailableAt() {
      return this.challenge?.available_at;
    },
    challengeHeadingTitle() {
      if (this.challengeAvailableAt) {
        return new Date(this.challengeAvailableAt) > new Date() ? "Next Challenge" : "Current Challenge";
      }
      return "Challenge";
    }
  },
  apollo: {
    resultCurrentChallenge: {
      query: GetCurrentChallengeId,
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <SuawMainContent :show-gradient="true" size="large-small-gap" no-top-margin-desktop>
    <SuawSubheaderTwo
      title="Challenges"
      description="Challenge yourself to write every day! Sign up for five days of prompts designed to take you on a thematic writing journey. Unlock a new prompt each day and write for 30 minutes to fire up your creativity and share with the community. "
      :image-source="subheaderImage"
      image-alt="Challenge Discovery"
    />
    <SuawDivider />
    <SuawInputGroup direction="column" group-gap="triple">
      <SuawHeading level="3" :content="challengeHeadingTitle" />
      <ChallengeBannerPipe v-if="challengeId" :challenge-id="challengeId">
        <template #button>
          <SuawButton
            class="suaw-challenge-banner__button"
            size="large"
            button-text="VIEW CHALLENGE!"
            @click="$router.push({ name: 'Challenge', params: { id: challengeId, slug: challengeSlug } })"
          />
        </template>
      </ChallengeBannerPipe>
      <SuawEmptyState v-else message="There is no upcoming Challenge." />
    </SuawInputGroup>
    <SuawDivider class="challenge-divider" />
    <ChallengeListPipe />
    <SuawDivider class="challenge-bottom-divider" />
    <WriteWithUs />
  </SuawMainContent>
</template>

<style lang="scss" scoped>
.challenge-divider {
  margin-top: 16px;
  @media (max-width: 650px) {
    margin-top: 0px;
  }
}
.challenge-bottom-divider {
  margin: 16px 0px;
}
</style>
