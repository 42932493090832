import { render, staticRenderFns } from "./ChallengeDiscovery.vue?vue&type=template&id=701d6352&scoped=true"
import script from "./ChallengeDiscovery.vue?vue&type=script&lang=js"
export * from "./ChallengeDiscovery.vue?vue&type=script&lang=js"
import style0 from "./ChallengeDiscovery.vue?vue&type=style&index=0&id=701d6352&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701d6352",
  null
  
)

export default component.exports