<script>
import { SuawThumbnail, SuawHeading, SuawParagraph, SuawIcon } from "@/components";

export default {
  name: "ChallengeCard",
  components: { SuawThumbnail, SuawHeading, SuawParagraph, SuawIcon },
  props: {
    challenge: {
      type: Object,
      required: true,
    },
  },
  computed: {
    writersText() {
      return `${this.challenge.challenge_users_aggregate.aggregate.count} Writers`;
    },
    challengeDate() {
      const date = new Date(this.challenge.available_at);
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      return `${month} ${year} Challenge`;
    },
    route() {
      const route = this.$router.resolve({
        name: "Challenge",
        params: {
          id: this.challenge.id,
          slug: this.challenge.slug
        }
      });
      return route.href;
    }
  },
};
</script>

<template>
  <router-link :to="route" class="suaw-challenge-card">
    <div class="suaw-challenge-card__main-container">
      <div class="suaw-challenge-card__thumbnail-container">
        <SuawThumbnail
          class="suaw-challenge-card__thumbnail"
          :content="challenge.image_url"
          size="fit"
        />
      </div>
      <div class="suaw-challenge-card__content">
        <div class="suaw-challenge-card__header">
          <SuawParagraph
            class="suaw-challenge-card__challenge-date"
            weight="normal"
            size="small"
            :text="challengeDate"
          />
          <div class="suaw-challenge-card__writers-group">
            <SuawIcon icon="IconUser" color="carbon" size="medium"/>
            <SuawParagraph
              class="suaw-challenge-card__writers"
              weight="normal"
              size="small"
              :text="writersText"
            />
          </div>
        </div>
        <SuawHeading
          class="suaw-challenge-card__title"
          level="5"
          :content="challenge.title"
        />
        <SuawParagraph
          class="suaw-challenge-card__description"
          weight="normal"
          size="small"
          :text="challenge.body_json"
        />
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.suaw-challenge-card {
  width: 100%;
  height: 385px;
  text-decoration: none;
  transition: transform 0.25s ease;
  display: block;

  &:hover {
    transform: scale(0.99);
  }

  &__main-container {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 8px;
    height: 100%;
    border-radius: var(--sem-radius-md);
  }

  &__thumbnail-container {
    position: relative;
    height: 240px;
    width: 100%;
    border-radius: var(--sem-radius-md);
    overflow: hidden;
  }

  &__content {
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: 8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__challenge-date {
    color: var(--sem-color-neutral-medium);
    font-weight: 500;
  }

  &__writers-group {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__writers {
    color: var(--sem-color-text-medium);
    font-weight: 500;
    font-size: 16px;
  }

  &__title {
    color: var(--sem-color-text-dark);
  }

  &__description {
    color: var(--sem-color-text-medium);
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}
</style>
