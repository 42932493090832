<script>
import { SuawGridSection } from "@/components";
import ChallengeCard from "./ChallengeCard.vue";

export default {
  name: "ChallengeListView",
  components: {
    SuawGridSection,
    ChallengeCard
  },
  props: {
    challenges: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
  <SuawGridSection v-if="!isLoading" show-header header-label="Past Challenges">
    <ChallengeCard v-for="challenge in challenges" :key="challenge.id" :challenge="challenge" />
  </SuawGridSection>
  <div v-else>
    Loading...
  </div>
</template>
