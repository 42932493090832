<script>
import { GetChallenges } from "../challengeDiscoveryOperations.gql";
import ChallengeListView from "./ChallengeListView.vue";
import { SuawHeading, SuawEmptyState } from "@/components";
export default {
  name: "ChallengeListPipe",
  components: {
    ChallengeListView,
    SuawEmptyState,
    SuawHeading
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    }
  },
  apollo: {
    resultChallenges: {
      query: GetChallenges,
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <div>
    <div v-if="isLoading" class="challenge-list-empty-state">
      <SuawHeading level="3" content="Past Challenges" />
      <SuawEmptyState message="Loading challenges..." />
    </div>
    <ChallengeListView v-else-if="resultChallenges.length > 0" :challenges="resultChallenges" :is-loading="isLoading" />
    <div v-else class="challenge-list-empty-state">
      <SuawHeading level="3" content="Past Challenges" />
      <SuawEmptyState message="There are no past challenges." />
    </div>
  </div>
</template>

<style lang="scss">
.challenge-list-empty-state {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
